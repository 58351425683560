import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { loadCompanies, setIsActiveOnCompany } from '../actions'
import logo from '../media/green-logo-icon.svg'
import CheeseburgerMenu from 'cheeseburger-menu'
import { API_ENDPOINT } from '../config'
import Checkbox from 'react-simple-checkbox';

class GalleryPage extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      showCompanies: false,
    }
  }

  componentWillMount() {
    this.props.loadCompanies()
  }

  deleteGDPR() {
    localStorage.removeItem('mail')
    localStorage.removeItem('photos')
    axios.post(`${API_ENDPOINT}/signup/revokeTerms`, {email: this.props.mail})
  }

  render() {
    const { mail, organizations, closeMenu, setIsActiveOnCompany } = this.props
    const { showCompanies } = this.state
    const numberOfActiveCompanies = organizations ? organizations.filter(x => x.isActive).length : 0

    return (
      <CheeseburgerMenu
        isOpen={this.props.isOpen}
        closeCallback={() => closeMenu()}
        width={280}>
        <div className="menu-content">
          <div className="close" onClick={() => closeMenu()}>&times;</div>
          <div className="logo">
            <img src={logo} alt="logo" />
            <div className="name">konto</div>
            <div className="special">snap</div>
          </div>
          {organizations && <div className="item">
            <div onClick={() => this.setState({ showCompanies: !showCompanies })}>
              {`${numberOfActiveCompanies} virksomheder`}
            </div>
            {showCompanies && <div>
              {organizations.sort((a, b) => a.name.localeCompare(b.name)).map(org => {
                return <div className="item">
                  <Checkbox
                    size={2}
                    borderThickness={1}
                    color={{ backgroundColor: '#000', borderColor: '#000', uncheckedBorderColor: '#000', tickColor: '#fff' }}
                    onChange={(isChecked) => setIsActiveOnCompany(org, isChecked)}
                    checked={org.isActive}
                  />
                  {org.name}
                </div>
              })}
            </div>}
          </div>}
          <div className="item">
            <Link to="/signup">Skift konto</Link>
          </div>
          <div className="item">
            <a href="https://kontolink-prod.appspot.com">Åbn kontolink</a>
          </div>
          <div className="item">
            <Link to="/" onClick={() => this.deleteGDPR()}>Tilbagekald GDPR samtykke</Link>
          </div>
          <div className="user">
            Logget ind som
            <br />
            {mail}
          </div>
        </div>
      </CheeseburgerMenu>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mail: state.mail,
    identityId: state.identityId,
    organizations: state.organizations,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadCompanies,
    setIsActiveOnCompany,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalleryPage))