import React from 'react'
import logo from '../media/white-logo-icon.svg'
import menu from '../media/menu.svg'

export const GalleryHeader = (props) => {
  return (
    <div className="galleryHeader">
      <div className="menuBtn" onClick={props.menu}>
        <img height="20" src={menu} alt="back" />  
      </div>
      <div className="brand">
        <img src={logo} alt="logo" />
        <div className="name">konto</div>
        <div className="special">snap</div>
      </div>
      <div style={{width:'50px'}}></div>
    </div>
  )
}