const URL = process.env.URL || 'http://localhost:3000'
const APP_VERSION = '1.0'
let API_ENDPOINT = '-- unset --'
let GA_TRACKING_ID = '-- unset --'
let STACK_DRIVER_KEY = null
let STACK_DRIVER_APP = 'localhost'

const hostname = window && window.location && window.location.hostname

if (hostname.indexOf('localhost') > -1) {
  API_ENDPOINT = (process.env.SERVER_URL || 'http://localhost:8080') + '/api'
} else if (hostname.indexOf('kontolink-snap-staging') > -1) { // staging
  API_ENDPOINT = 'https://kontolink-snap-staging.appspot.com/api'
  STACK_DRIVER_KEY = 'AIzaSyB9NQn3yK6NCWx4vXJdVlb2I0cNRaG9Bdw'
  STACK_DRIVER_APP = 'kontolink-snap-staging'
} else if (hostname.indexOf('kontolink') > -1) { // production
  API_ENDPOINT = 'https://kontolink-snap-prod.appspot.com/api'
  GA_TRACKING_ID = 'UA-126192746-2'
  STACK_DRIVER_KEY = 'AIzaSyAFiYpvkj5bSty9j988J3nNLAjJZpPT74U'
  STACK_DRIVER_APP = 'kontolink-snap-prod'
} else {
  throw new Error(`Don't know the API_ENDPOINT for ${hostname}`)
}

export {
  URL,
  API_ENDPOINT,
  APP_VERSION,
  GA_TRACKING_ID,
  STACK_DRIVER_KEY,
  STACK_DRIVER_APP,
}
