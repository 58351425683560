import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { logSomething } from '../actions'
import KontolinkSignup from '../components/KontolinkSignup'
import EmailSignup from '../components/EmailSignup'
import { SignupHeader } from '../components/SignupHeader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import kontolinklogo from '../common-ui/media/kontolink-logo.png'
import './OnboardingPage.scss'

class OnboardingPage extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      dataUri: '',
      connectEmail: false,
      connectKontolink: false
    }
  }

  componentDidMount () {
    this.props.logSomething(`A user opened kontosnap onboarding page: mail: ${this.props.mail}, accessKey: ${this.props.accessKey}`)
  }

  goBack (state) {
    this.setState(state)
  }

  render () {
    return (
      <div id="onboardingContainer">
        {this.state.connectEmail ? <EmailSignup Prev={() => this.goBack({connectEmail: false})} /> 
        : this.state.connectKontolink ? <KontolinkSignup Prev={() => this.goBack({connectKontolink: false})}/>
        : <div className="landingPage">
          <SignupHeader />
          <div className="headLine">
            Digitaliser dine bilag
          </div>
          <div className="description">
            Tag billeder af kvitteringer på farten. Kontosnap verificerer billedkvaliteten og videresender automatisk billederne til din bogholder.
          </div>
          <div className="discontinueOverlay">
            Denne App er ophørt og kan ikke længere bruges til at tage billeder.
            <br />
            Brug <a className="snapLink" href="https://kontolink.com/kontosnap/" rel="noopener noreferrer" target="_blank">den nye Kontosnap app</a> ved at klikke på linket!
          </div>
          <div className="bottomContainer">
            <div className="description">Overfør automatisk billeder til</div>
            <div className="button blue" onClick={() => this.setState({connectKontolink: true})}>
              <img src={kontolinklogo} height="24" alt="kontolink"/>
              <div>kontolink</div>
            </div>
            <div className="button transparent" onClick={() => this.setState({connectEmail: true})}>Email</div>
          </div>
        </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    logSomething
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingPage))
