import axios from 'axios'

import { API_ENDPOINT } from '../config'
import { PHOTO_RESULT, ADD_PHOTO, SEND_MAIL, REMOVE_PHOTO, SET_MAIL, SET_KONTOLINK, UPDATE_NOTE_ON_PHOTO, RECEIVED_ERROR, CLEAR_ERROR, FETCHED_ORGANIZATIONS, LOGGED_SOMETHING } from "../constants/action-types"
import errorHandler from '../errorHandler'

export function sendMail(photo, mail, accessKey, cb) {
  const url = `${API_ENDPOINT}/mail/sendPhoto`

  return dispatch => {
    axios.post(url, { photo: photo.src, note: photo.note, mail: mail, accessKey: accessKey }, { 'content-type': 'application/json' })
      .then(response => {
        let _id = undefined
        if (response.data != null) {
          _id = response.data._id
        }

        dispatch({
          type: SEND_MAIL,
          payload: photo,
          _id,
        })
      })
      .catch(err => {
        errorHandler.report(err)
        alert('Der skete en fejl da vi prøvede at sende billedet. Prøv igen eller kontakt vores support.')
        cb()
      })
  }
}

export function scanPhoto(photo) {
  const url = `${API_ENDPOINT}/photo/scan`
  return dispatch => {
    axios.post(url, { image: photo }, { 'content-type': 'application/json' })
      .then(response => {
        if (response.data == null || response.data.id == null) {
          const error = new Error('scanned photo id is null')
          error.data = response.data
          throw error
        }

        dispatch({
          type: PHOTO_RESULT,
          payload: response.data
        })
      })
      .catch(err => {
        errorHandler.report(err)
        dispatch({
          type: PHOTO_RESULT,
          payload: { id: photo.timestamp, errorMessage: 'Fejl under scanning' }
        })
      })
  }
}

export function photoError(photo) {
  return dispatch => {
    dispatch({
      type: PHOTO_RESULT,
      payload: { id: photo.timestamp, errorMessage: 'Netværksfejl' }
    })
  }
}

export function addPhoto(photo) {
  return dispatch => {
    dispatch({
      type: ADD_PHOTO,
      payload: photo
    })
  }
}

export function removePhoto(photo) {
  return dispatch => {
    dispatch({
      type: REMOVE_PHOTO,
      payload: photo
    })
  }
}

export function setMail(mail) {
  return dispatch => {
    dispatch({
      type: SET_MAIL,
      payload: mail
    })
  }
}

export function kontolinkProfile(mail, pin, accessKey, identityId) {
  return dispatch => {
    dispatch({
      type: SET_KONTOLINK,
      payload: { mail, pin, accessKey, identityId }
    })
  }
}

export function validatePin(data, callback, errcallback, _this) {
  const url = `${API_ENDPOINT}/signup/validatePin`
  return dispatch => {
    axios.post(url, { mail: data.email, pin: data.pin }, { 'content-type': 'application/json' })
      .then(response => {
        if (response.data.isValid) {
          callback({ accessKey: response.data.accessKey, identityId: response.data.identityId })
        } else {
          errcallback(_this)
        }
      })
      .catch(err => {
        errorHandler.report(err)
      })
  }
}

export function saveNoteOnPhoto(photo, note, accessKey) {
  if (photo == null || photo._id == null) {
    return null
  }

  const url = `${API_ENDPOINT}/mail/updateNoteOnPhoto`

  return dispatch => {
    axios.post(url, { photoId: photo._id, note, accessKey }, { 'content-type': 'application/json' })
      .then(res => {
        dispatch({
          type: UPDATE_NOTE_ON_PHOTO,
          photo,
          note,
        })
      })
      .catch(err => {
        errorHandler.report(err)
        alert('Der skete en fejl da vi prøvede at opdatere noten. Prøv igen')
      })
  }
}

export function onError(err) {
  return dispatch => {
    handleError(dispatch, err)
  }
}

export function clearError() {
  return dispatch => {
    dispatch({
      type: CLEAR_ERROR,
    })
  }
}

export function loadCompanies() {
  return (dispatch, getState) => {
    const { accessKey, identityId } = getState()
    if (!identityId) {
      return
    }

    const url = `${API_ENDPOINT}/organizations/?accessKey=${accessKey}`
    axios.get(url).then(response => {
      dispatch({
        type: FETCHED_ORGANIZATIONS,
        organizations: response.data,
      })
    })
    .catch(err => {
      errorHandler.report(err)
      alert('Der skete en fejl da vi prøvede at opdatere virskomheder. Prøv igen')
    })
  }
}

export function setIsActiveOnCompany(company, isActive) {
  return (dispatch, getState) => {
    const { accessKey, organizations } = getState()
    const existing = organizations.filter(x => x.id !== company.id)
    const data = [...existing, {...company, isActive}]

    const url = `${API_ENDPOINT}/organizations/?accessKey=${accessKey}`
    axios.put(url, data).then(response => {
      dispatch({
        type: FETCHED_ORGANIZATIONS,
        organizations: response.data,
      })
    })
    .catch(err => {
      errorHandler.report(err)
      alert('Der skete en fejl da vi prøvede at opdatere virksomheder. Prøv igen')
    })
  }
}

export function logSomething (message) {
  return dispatch => {
    const data = {message}

    const url = `${API_ENDPOINT}/track-event`
    axios.post(url, data).then(response => {
      dispatch({
        type: LOGGED_SOMETHING,
        organizations: response.data,
      })
    })
    .catch(err => {
      errorHandler.report(err)
      alert('Der skete en fejl da vi prøvede noget. Prøv igen')
    })
  }
}

function handleError(dispatch, err) {
  errorHandler.report(err)
  dispatch({
    type: RECEIVED_ERROR,
    error: err,
  })
}
