import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo'
import { Link } from 'react-router-dom'
import { scanPhoto, addPhoto, setMail, kontolinkProfile, logSomething } from '../actions'
import { isUserIOS } from '../utilities/agentHelper'
import { GA_TRACKING_ID } from '../config'
import PageVisibility from 'react-page-visibility';
import './CapturePage.scss'
import library from '../media/library.svg'
import ReactGA from 'react-ga'
import Popup from "reactjs-popup"
import GalleryItemNote from "../components/GalleryItemNote"

ReactGA.initialize(GA_TRACKING_ID)
class CapturePage extends Component {
  constructor (props, context) {
    super(props, context)
    this.cameraPhoto = null;
    this.videoRef = React.createRef()
    this.state = {
      redirect: false,
      cameraAllowed: true,
      promptForNote: false,
      picture: null,
    }
  }

  takePhoto () {
    const config = {
      imageType : IMAGE_TYPES.JPG,
      isImageMirror : false
    }

    let dataUri = this.cameraPhoto.getDataUri(config)
    const picture = {
      timestamp: + new Date(),
      src: dataUri,
      text: false,
      date: false,
      money: false,
      processing: true,
      forceEmail: false,
    }
    this.setState({ picture })

    if (this.props.accessKey) {
      this.scanPhoto(picture)
    } else {
      this.setState({ promptForNote: true })
    }

    try {
      ReactGA.event({
        label: `${this.props.mail.replace('@', '$')}`,
        category: 'Photo',
        action: `Captured to ${this.props.accessKey ? 'kontolink' : 'email'}`
      })
    } catch (e) {
      console.error('Exception calling GA for tx approval', e)
    }
  }

  scanPhoto (picture) {
    // if we add a note, we cannot set the timestamp until the user proceeds
    picture.timestamp = new Date().getTime()
    this.props.addPhoto(picture)
    this.props.scanPhoto({...picture, mail: this.props.mail, accessKey: this.props.accessKey})
    this.setState({redirect: true})
  }

  async componentDidMount () {
    if (localStorage.getItem('mail') && !this.props.mail) {
      if (localStorage.getItem('accessKey')) {
        this.props.kontolinkProfile(localStorage.getItem('mail'), null, localStorage.getItem('accessKey'), localStorage.getItem('identityId'))
      } else {
        this.props.setMail(localStorage.getItem('mail'))
      }
    }

    this.cameraPhoto = new CameraPhoto(this.videoRef.current)
    try {
      await this.cameraPhoto.startCamera(FACING_MODES.ENVIRONMENT, (isUserIOS() && window.innerWidth > 320) ? {width: 1024, height: 768} : {width: 1280, height: 960})
    } catch {
      this.setState({cameraAllowed: false})
    }
    this.props.logSomething(`A user opened kontosnap capture page: mail: ${this.props.mail}, accessKey: ${this.props.accessKey}`)
  }

  handleVisibilityChange = isVisible => {
    if (!isVisible && isUserIOS()) {
      this.setState({ redirect: true });
    }
  }

  getNoteEditPopup () {
    if (!this.state.promptForNote) {
      return null
    }

    // unfortunately reactjs-popup is very opinionated about it's styling
    // so we have to override some defaults from it
    const style = {
      margin: '22px 12px 0px 12px',
      width: 'calc(100% - 24px)',
      height: '296px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      border: 'auto',
      borderRadius: '5px',

      padding: '0',
    }

    return <Popup contentStyle={style} open modal>
      <GalleryItemNote
        value={''}
        cancel={() => this.scanPhoto(this.state.picture)}
        save={note => {
          const picture = this.state.picture
          picture.note = note
          this.scanPhoto(picture)
        }}
        cancelText="Fortsæt uden note"
        saveText="Gem"
      />
    </Popup>
  }

  render () {
    if (this.state.redirect) {
      return <Redirect push to="/gallery/" />;
    }

    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <div id="captureContainer">
          <div className="videoContainer">
            <video id="camera"
              ref={this.videoRef}
              autoPlay={true}
              playsInline
            />
          </div>
          {!this.state.cameraAllowed
          ? isUserIOS() ? <div className="helpText">Vi kunne ikke få adgang til dit kamera. Verificér at du har tilladt brug af kamera under Safari inde i indstilliger.</div>
          : <div className="helpText">Vi kunne ikke få adgang til dit kamera. Verificér at du ikke har blokeret adgang til kamera og prøv at genindlæs siden.</div> : ''}
          {/* <div className="captureBtn">
            <div className="fa" onClick={() => this.takePhoto()} />
          </div> */}
          <div className="discontinueOverlay">
            Denne App er ophørt og kan ikke længere bruges til at tage billeder.
            <br />
            Brug <a className="snapLink" href="https://kontolink.com/kontosnap/" rel="noopener noreferrer" target="_blank">den nye Kontosnap app</a> ved at klikke på linket!
          </div>
          <Link to='/gallery/'>
            <div className="galleryBtn">
              <img height="39" src={library} alt="gallery" />
            </div>
          </Link>
          {this.getNoteEditPopup()}
        </div>
      </PageVisibility>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    mail: state.mail,
    accessKey: state.accessKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    scanPhoto,
    addPhoto,
    setMail,
    kontolinkProfile,
    logSomething
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CapturePage))