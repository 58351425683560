import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { onError } from '../actions'
import { Redirect } from 'react-router'

export class ErrorBoundary extends React.Component {
  componentDidCatch (error) {
    this.props.onError(error)
  }

  render () {
    if (this.props.error && window.location.hash !== '#/error') {
      return <Redirect push to={{
        pathname: '/error',
        state: { referrer: window.location.hash }
      }} />
    }

    // No errors were thrown
    return this.props.children
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    onError
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
