import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validatePin } from '../../actions'

class PinCode extends Component {
  constructor () {
    super()

    this.pin1 = React.createRef()
    this.pin2 = React.createRef()
    this.pin3 = React.createRef()
    this.pin4 = React.createRef()

    this.state = {
      shakeInput: false
    }
  }

  componentDidMount () {
    setTimeout(() => {
      this.pin1.current.focus()
    }, 500); 
  }

  handlePinInput = (e, next) => {
    if (e.target.value !== '') {
      e.target.value = e.target.value.slice(-1)
      if ('0123456789'.indexOf(e.target.value) === -1) {
        e.preventDefault()
        e.target.value = ''
        return
      }
      next.focus ? next.focus() : next()
    }
  }

  handleKeyDown (event, next) {
    if (event.keyCode === 8) {
      event.target.value = ''
      next.focus ? next.focus() : next()
    }
  }

  validatePin = () => {
    const pin = '' + this.pin1.current.value + this.pin2.current.value + this.pin3.current.value + this.pin4.current.value
    this.props.validatePin({pin: pin, ...this.props.info}, this.props.pinSuccess, this.pinFailed, this)
  }

  pinFailed (_this) {
    _this.pin1.current.value = ''
    _this.pin2.current.value = ''
    _this.pin3.current.value = ''
    _this.pin4.current.value = ''
    _this.pin1.current.focus()
    _this.shakeInput()
  }

  shakeInput () {
    this.setState({ shakeInput: true })
    setTimeout(function () {
      this.setState({ shakeInput: false })
    }.bind(this), 1000)
  }

  render () {
    return (
      <form id='pin' autoComplete='off'>
        <input className={`autotest_pin${this.state.shakeInput ? ' shakeInput' : ''}`} onKeyDown={(e) => this.handleKeyDown(e, this.pin1.current)} required type='tel' ref={this.pin1} name='pin1' onChange={(e) => this.handlePinInput(e, this.pin2.current)} autoFocus />
        <input className={this.state.shakeInput ? 'shakeInput' : ''} onKeyDown={(e) => this.handleKeyDown(e, this.pin1.current)} required type='tel' ref={this.pin2} name='pin2' onChange={(e) => this.handlePinInput(e, this.pin3.current)} />
        <input className={this.state.shakeInput ? 'shakeInput' : ''} onKeyDown={(e) => this.handleKeyDown(e, this.pin2.current)} required type='tel' ref={this.pin3} name='pin3' onChange={(e) => this.handlePinInput(e, this.pin4.current)} />
        <input className={this.state.shakeInput ? 'shakeInput' : ''} onKeyDown={(e) => this.handleKeyDown(e, this.pin3.current)} required type='tel' ref={this.pin4} name='pin4' onChange={(e) => this.handlePinInput(e, this.validatePin)} />
      </form>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    validatePin
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(PinCode)
