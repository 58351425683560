import StackdriverErrorReporter from 'stackdriver-errors-js'
import { APP_VERSION, STACK_DRIVER_KEY, STACK_DRIVER_APP } from './config'

export class ErrorHandler {
  start () {
    if (STACK_DRIVER_KEY == null) {
      return
    }

    this.stackdriver = new StackdriverErrorReporter()
    this.stackdriver.start({
      key: STACK_DRIVER_KEY,
      projectId: STACK_DRIVER_APP,
      service: 'capture-ui',
      reportUncaughtExceptions: true,
      reportUnhandledPromiseRejections: true,
      version: APP_VERSION.replace(/\./g, '-')
    })
  }

  report (error) {
    if (this.stackdriver == null) {
      console.log(error)
    } else {
      this.stackdriver.report(error)
    } 
  }
}

const instance = new ErrorHandler()
export default instance
