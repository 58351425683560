import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './sass/common.scss';
import App from './App';
import { Provider } from 'react-redux'
import store from './store/index'
import { HashRouter as Router } from 'react-router-dom'
import errorHandler from './errorHandler'

import OnboardingPage from './containers/OnboardingPage'
import CapturePage from './containers/CapturePage'
import GalleryPage from './containers/GalleryPage'
import ImagePage from './containers/ImagePage'
import ErrorPage from './containers/ErrorPage'

window.addEventListener('DOMContentLoaded', function () {
  errorHandler.start()
})

const routes = [
  {
    path: '/',
    exact: true,
    content: CapturePage,
  },
  {
    path: '/signup',
    exact: true,
    content: OnboardingPage,
    public: true
  },
  {
    path: '/gallery',
    exact: true,
    content: GalleryPage,
  },
  {
    path: '/image',
    exact: true,
    content: ImagePage,
  },
  {
    path: '/error',
    exact: true,
    content: ErrorPage,
  },
]

ReactDOM.render(
    <Provider store={store}>
        <Router>
          <App routes={routes} />
        </Router>
    </Provider>
  , document.getElementById('root')
);

