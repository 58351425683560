export const PHOTO_RESULT = "PHOTO_RESULT"
export const ADD_PHOTO = "ADD_PHOTO"
export const SEND_MAIL = "SEND_MAIL"
export const REMOVE_PHOTO = "REMOVE_PHOTO"
export const SET_MAIL = "SET_MAIL"
export const SET_KONTOLINK = "SET_KONTOLINK"
export const UPDATE_NOTE_ON_PHOTO = "UPDATE_NOTE_ON_PHOTO"
export const RECEIVED_ERROR = "RECEIVED_ERROR"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const FETCHED_ORGANIZATIONS = "FETCHED_ORGANIZATIONS"
export const LOGGED_SOMETHING = "LOGGED_SOMETHING"