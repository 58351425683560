const isUserIOS = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
}

const isUserAndroid = () => {
  return /(android)/i.test(navigator.userAgent)
}

export {
  isUserIOS,
  isUserAndroid
}
