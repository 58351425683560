import { PHOTO_RESULT, ADD_PHOTO, SEND_MAIL, REMOVE_PHOTO, SET_MAIL, SET_KONTOLINK, UPDATE_NOTE_ON_PHOTO, RECEIVED_ERROR, CLEAR_ERROR, FETCHED_ORGANIZATIONS } from "../constants/action-types"
import { MAX_PHOTOS } from "../constants/photos"

const initialState = {
  photos: localStorage.getItem('photos') ? JSON.parse(localStorage.getItem('photos')) : [],
  mail: localStorage.getItem('mail'),
  identityId: localStorage.getItem('identityId'),
  pin: localStorage.getItem('pin'),
  accessKey: localStorage.getItem('accessKey'),
  error: null
}

function updatePhoto (photos, photo, newProperties) {
  let clone = JSON.parse(JSON.stringify(photos))
  for (let i = 0; i < clone.length; i++) {
    if (String(clone[i].timestamp) === String(photo.id)) {
      clone[i] = {...clone[i], ...photo, ...newProperties}
      break
    }
  }


  return savePhotos(clone)
}

function removePhoto (photos, photo) {
  let clone = JSON.parse(JSON.stringify(photos))
  for (let i = 0; i < clone.length; i++) {
    if (String(clone[i].timestamp) === String(photo.id)) {
      clone.splice(i, 1)
      break
    }
  }

  return savePhotos(clone)
}

function savePhotos (photos) {
  try {
    // localStorage on older iphones is very limited. We cannot keep 10 photos on these devices.
    localStorage.setItem('photos', JSON.stringify(photos))
  } catch (ex) {
    if (photos.length > 3) {
      return removePhoto(photos, photos[photos.length - 1])
    }

    throw ex
  }

  return photos
}

function rootReducer (state = initialState, action) {
  // ignore actions like @@redux/INIT9.3.2.d.r.a that come from redux
  if (action.type.startsWith('@@')) {
    return state
  }

  switch (action.type) {
    case SET_MAIL:
      if (action.payload !== localStorage.getItem('mail')) {
        localStorage.setItem('mail', action.payload)
        localStorage.removeItem('photos')
      }
      localStorage.removeItem('pin')
      localStorage.removeItem('identityId')
      localStorage.removeItem('accessKey')
      return {photos: localStorage.getItem('photos') ? JSON.parse(localStorage.getItem('photos')) : [],
              mail: action.payload, pin: null, accessKey: null}
    case SET_KONTOLINK:
      if (action.payload.mail !== localStorage.getItem('mail')) {
        localStorage.setItem('mail', action.payload.mail)
        localStorage.removeItem('photos')
      }

      localStorage.setItem('pin', action.payload.pin)
      localStorage.setItem('accessKey', action.payload.accessKey)
      localStorage.setItem('identityId', action.payload.identityId)
      return {photos: localStorage.getItem('photos') ? JSON.parse(localStorage.getItem('photos')) : [],
              mail: action.payload.mail, pin: action.payload.pin, accessKey: action.payload.accessKey, identityId: action.payload.identityId}
    case PHOTO_RESULT:
      return {...state, photos: updatePhoto(state.photos, action.payload, {processing: false, _id: action.payload._id})}
    case ADD_PHOTO:
      const newPhotos = [action.payload].concat(state.photos).slice(0, MAX_PHOTOS)
      return {...state, photos: newPhotos}
    case SEND_MAIL:
      return {...state, photos: updatePhoto(state.photos, action.payload, {forceEmail: true, _id: action._id})}
    case REMOVE_PHOTO:
      return {...state, photos: removePhoto(state.photos, action.payload)}
    case UPDATE_NOTE_ON_PHOTO:
      return {...state, photos: updatePhoto(state.photos, action.photo, {note: action.note})}
    case RECEIVED_ERROR:
      return {...state, error: action.error}
    case CLEAR_ERROR:
      return {...state, error: null}
    case FETCHED_ORGANIZATIONS:
      return {...state, organizations: action.organizations}
    default:
      return state
    }
}

export default rootReducer
