import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GalleryHeader } from '../components/GalleryHeader'
import GalleryItem from '../components/GalleryItem'
import { sendMail, removePhoto, photoError, saveNoteOnPhoto, logSomething } from '../actions'
import camera from '../media/camera-white.svg'
import { MAX_PHOTOS } from "../constants/photos"
import './GalleryPage.scss'
import Menu from './Menu'

class GalleryPage extends Component {
  constructor(props, context) {
    super(props, context)

    this.monthNames = ["JAN", "FEB", "MAR", "APR", "MAJ", "JUN", "JUL", "AUG", "SEP", "OKT", "NOV", "DEC"]

    this.state = {
      detailView: false,
      menuOpen: false
    }
  }

  componentDidMount() {
    if (this.props.photos && this.props.photos.length > 0) {
      try {
        localStorage.setItem('photos', JSON.stringify(this.props.photos))
      } catch (ex) {
        this.props.removePhoto(this.props.photos[this.props.photos.length - 1])
      }
      this.props.photos.forEach(photo => {
        if (photo.processing && (new Date().getTime() - photo.timestamp) / 1000 > 10)
        this.props.photoError(photo)
      })
    }
    this.props.logSomething(`A user opened kontosnap gallery page: mail: ${this.props.mail}, accessKey: ${this.props.accessKey}`)
  }

  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  handleSendMail = (photo, cb) => {
    this.props.sendMail(photo, this.props.mail, this.props.accessKey, cb)
  }

  handleRemovePhoto(photo) {
    this.props.removePhoto(photo, this.props.mail)
  }

  saveNoteOnPhoto (photo, note) {
    this.props.saveNoteOnPhoto(photo, note, this.props.accessKey)
  }

  render() {
    let gallery = []
    const dates = this.props.photos.map(picture => {
      const date = new Date(picture.timestamp)
      return this.monthNames[date.getMonth()] + " " + date.getDate()
    })
    const uniqueDates = [...new Set(dates)]

    gallery = uniqueDates.map(uniqueDate => {
      let photosInDate = this.props.photos.filter((picture) => {
        const date = new Date(picture.timestamp)
        return this.monthNames[date.getMonth()] + " " + date.getDate() === uniqueDate
      }).map(pic => { return <GalleryItem data={pic} key={pic.timestamp}
                                          handleSendMail={this.handleSendMail}
                                          handleNewPhoto={(x) => this.handleRemovePhoto(x)}
                                          saveNoteOnPhoto={(p, n) => this.saveNoteOnPhoto(p, n) } /> })
      return (
        <div key={uniqueDate}>
          <div className='day'>
            {uniqueDate}
          </div>
          {photosInDate}
        </div>
      )
    })

    return (
      <div>
        <Menu isOpen={this.state.menuOpen} closeMenu={() => this.closeMenu()}/>
        <div id='galleryPage'>
          <GalleryHeader menu={() => this.openMenu()} />
          <div className="discontinueOverlay">
            Denne App er ophørt og kan ikke længere bruges til at tage billeder.
            <br />
            Brug <a className="snapLink" href="https://kontolink.com/kontosnap/" rel="noopener noreferrer" target="_blank">den nye Kontosnap app</a> ved at klikke på linket!
          </div>
          <div className='galleryContainer'>
            <div className='description'>
              {gallery.length > 0 ? `Viser ${MAX_PHOTOS} nyeste billeder` : "Her vil du kunne se dine seneste billeder"}
            </div>
            {gallery}
          </div>
          <Link to='/'>
            <div className='cameraBtn'>
              <img src={camera} alt="camera" />
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    photos: state.photos,
    mail: state.mail,
    accessKey: state.accessKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendMail,
    removePhoto,
    photoError,
    saveNoteOnPhoto,
    logSomething
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalleryPage))