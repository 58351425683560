import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import ErrorBoundary from './containers/ErrorBoundary'

const PrivateRoute = ({ component: Component, isSignedUp, ...rest }) => {
  if (window.location.href.split('?')[1]) {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1].replace("#/", ""))
    const email = urlParams.get('email')
    const accessKey = urlParams.get('accessKey')
    if (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (re.test(String(email).toLowerCase())) {
        if (accessKey) {
          localStorage.setItem('mail', email)
          localStorage.setItem('accessKey', accessKey)
        } else {
          localStorage.setItem('mail', email)
        }
        isSignedUp = true
        window.location.replace(window.location.origin)
      }
    }
  }

  return (
    <Route {...rest} render={(props) => (
      isSignedUp ? <Component {...props} />
        : <Redirect to='/signup' />
    )} />
  )
}

class App extends Component {

  render() {
    return (
      <div className="App">
        <ErrorBoundary>
        {this.props.routes.map((route, index) => {
          return route.public ? <Route key={index} path={route.path} exact={route.exact} component={route.content} />
            : <PrivateRoute key={index} isSignedUp={localStorage.getItem('mail')} component={route.content} path={route.path} exact={route.exact} />
        })}
        </ErrorBoundary>
      </div>
    )
  }
}

export default App