import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './ImagePage.scss'
import { Link } from 'react-router-dom'
import backArrow from '../media/arrow-left-white.svg'
import { saveNoteOnPhoto } from '../actions'
import GalleryItemNote from "../components/GalleryItemNote"

const getMark = (classN, text, element) => {
  if (element) {
    return (
      <div className={`${classN} font2`}>{text}<span className='check'>&#x2714;</span></div>
    )
  } else {
    return (
      <div className={`${classN} font2 noInfo`}>{text}<span className='cross'>&#10006;</span></div>
    )
  }
}

class ImagePage extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
    }
  }

  saveNoteOnPhoto (photo, note) {
    this.props.saveNoteOnPhoto(photo, note, this.props.accessKey)
  }

  render () {
    var photo = this.props.photos.find(x => x.id === this.props.location.photoId)
    if (photo == null) {
      // should we log this?
      return null
    }

    let headline = 'Information Mangler'
    if ((photo.text || photo.money || photo.date)) {
      headline = 'Information Detekteret'
    }

    return (
      <div id='imagePage'>
        <div className="imageHeader">
          <Link to='/gallery/'>
            <div className="backButton">
              <img height="24" src={backArrow} alt="back" />  
            </div>
          </Link>
          <div className="text">Billede</div>
        </div>
        <div className="imageContainer">
          <img src={photo.src} alt="detail" />  
        </div>
        {photo.processing
        ? <div className="info">
            <div className="headline">Detekterer Information</div>
            <div className="processing"><div className="loader" /></div>
          </div>
        : <div className="info">
            <div className="headline">{headline}</div>
            {getMark('info1', 'Tekst', photo.text)}
            {getMark('info2', 'Beløb', photo.money)}
            {getMark('info3', 'Dato', photo.date)}
          </div>
          }
        {photo.note &&
        <div>
          <GalleryItemNote
            value={photo.note}
            autoFocus={false}
            onBlur={(e) => this.props.saveNoteOnPhoto(photo, e.target.value, this.props.accessKey)}
            disabled={this.props.accessKey == null}
          />
        </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    photos: state.photos,
    accessKey: state.accessKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveNoteOnPhoto,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImagePage))