import React, { Component } from 'react'
import './GalleryItemNote.scss'

class GalleryItemNote extends Component {
    constructor(props, context) {
      super(props, context)
  
      this.state = {
          note: null,
      }
    }

    getValue () {
        var note = this.state.note
        if (note !== null) {
            return note
        }

        return this.props.value
    }

    render () {
        const { autoFocus, onBlur, save, cancel, disabled, cancelText, saveText } = this.props
        

        return <div className="gallery-item-note">
            <div className="header-text">Note</div>
            <textarea
                autoFocus={autoFocus !== false}
                className="note-input"
                onChange={e => this.setState({ note: e.target.value })}
                value={this.getValue() || ''}
                onBlur={onBlur}
                placeholder="Hvad har du lavet, med hvem?"
                disabled={disabled}
            />
            <div className="bottons botton-text">
                {cancel && <div onClick={cancel}>{cancelText || 'FORTRYD'}</div>}
                {save && <div onClick={() => save(this.getValue())}>{saveText || 'GEM'}</div>}
            </div>
        </div>
    }
}

export default GalleryItemNote