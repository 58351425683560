import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Popup from "reactjs-popup";
import NoteIcon from "../media/note-icon.svg"
import GalleryItemNote from "./GalleryItemNote"
import './GalleryItem.scss'

class GalleryItem extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      forceEmail: false,
      isEditingNote: false,
    }
  }

  sendMail () {
    if (!this.state.forceEmail) {
      this.setState({forceEmail: true})
      this.props.handleSendMail(this.props.data, this.setForceEmail)
    }
  }

  setForceEmail = () => {
    this.setState({forceEmail: false})
  }

  newPicture () {
    if (!this.state.forceEmail) {
      this.props.handleNewPhoto(this.props.data)
    }
  }

  getMark(classN, text, element) {
    if (element) {
      return (
        <div className={`${classN} font2`}>{text}<span className='check'>&#x2714;</span></div>
      )
    } else {
      return (
        <div className={`${classN} font2 noInfo`}>{text}<span className='cross'>&#10006;</span></div>
      )
    }
  }

  hasBeenProcessed () {
    if (this.props.data.forceEmail || this.props.data._id) {
      return true
    }

    return [this.props.data.text, this.props.data.money, this.props.data.date].filter(x => x).length > 1
  }

  canEditNote () {
    return !this.hasBeenProcessed() || this.props.data._id
  }

  editNote () {
    this.setState({ isEditingNote: true })
  }

  stopEditNote () {
    this.setState({ isEditingNote: false })
  }

  render () {
    const imageLink = { 
      pathname: "/image",
      photoId: this.props.data.id,
    }

    if (this.props.data.processing) {
      return <div className="galleryItemContainer">
        <div className="thumbnail"><Link to={imageLink}><img src={this.props.data.src} alt='thumbnail' className="img"/></Link></div>
        <div className="headline font1">Detekterer Information</div>
        <div className="processing"><div className="loader" /></div>
      </div>
    }

    let headline = 'Information Mangler'
    if (this.props.data.errorMessage) {
      headline = this.props.data.errorMessage
    } else if ((this.props.data.text || this.props.data.money || this.props.data.date)) {
      headline = 'Information Detekteret'
    }

    return (
      <div>
        <div className="galleryItemContainer">
            <div className="thumbnail">
              <Link to={imageLink}>
                <img src={this.props.data.src} alt="thumbnail" className="img" />
                {this.props.data.note && <img src={NoteIcon} alt="note" className="note" />}
              </Link>
            </div>
            <div className="headline font1">{headline}</div>
            {this.getMark('info1', 'Tekst', this.props.data.text)}
            {this.getMark('info2', 'Beløb', this.props.data.money)}
            {this.getMark('info3', 'Dato', this.props.data.date)}
            {(this.hasBeenProcessed())
            ? <div className="actionGrid">
                  <div className="left2 font4">{this.props.accessKey ? 'SENDT TIL KONTOLINK' : 'SENDT TIL MAIL'}</div>
                  <div className="right">{this.getNoteField()}</div>
              </div>
            : <div className="actionGrid">
                <Link to="/"><div className={`left font3 ${this.state.forceEmail ? 'disabled' : ''}`} onClick={() => this.newPicture()}>TAG NYT BILLEDE</div></Link>
                <div className={'right'}>
                  <div className={`font3 ${this.state.forceEmail ? 'disabled' : ''}`} onClick={() => this.sendMail()}>VIDERESEND</div>
                </div>
              </div>}
              {this.state.isEditingNote && this.getNoteEditPopup()}
          </div>
      </div>
    )
  }

  getNoteEditPopup () {
    if (!this.state.isEditingNote) {
      return null
    }

    // unfortunately reactjs-popup is very opinionated about it's styling
    // so we have to override some defaults from it
    const style = {
      margin: '22px 12px 0px 12px',
      width: 'calc(100% - 24px)',
      height: '296px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      border: 'auto',
      borderRadius: '5px',

      padding: '0',
    }

    return <Popup contentStyle={style} open modal>
      <GalleryItemNote
        value={this.props.data.note}
        cancel={() => this.stopEditNote()}
        save={note => {
          this.props.saveNoteOnPhoto(this.props.data, note)
          this.stopEditNote()
        }}
      />
    </Popup>
  }

  getNoteField () {
    if (!this.canEditNote()) {
      return null
    }

    return <div className="font3" onClick={() => this.editNote()}>Note</div>
  }
}

const mapStateToProps = (state) => {
  return {
    mail: state.mail,
    accessKey: state.accessKey
  }
}


export default withRouter(connect(mapStateToProps, null)(GalleryItem))
