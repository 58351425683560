import React from 'react'
import logo from '../media/green-logo-icon.svg'
import backArrow from '../media/arrow-left.svg'

export const SignupHeader = (props) => {
  return (
    <div className="header">
      <div className="backButton" onClick={() => {if (props.prev) {props.prev()}}}>
        {props.prev && 
        <img height="28" src={backArrow} alt="back" />  
        }
      </div>
      <div className="brand">
        <img src={logo} alt="logo" />
        <div className="name">konto</div>
        <div className="special">snap</div>
      </div>
      <div style={{width:'50px'}}></div>
    </div>
  )
}