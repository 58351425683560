import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SignupHeader } from '../components/SignupHeader'
import PinCode from '../common-ui/components/PinCode'
import kontolinklogo from '../common-ui/media/kontolink-logo.png'
import { kontolinkProfile } from '../actions'
import { API_ENDPOINT } from '../config'

import './KontolinkSignup.scss'

class KontolinkSignup extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      redirect: false,
      email: '',
      emailValid: false,
      showError: false,
      shakeInput: false,
      showPassword: false,
      errorMessage: '',
      name: 'kontolink',
      nextBtnDisabled: false
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(email).toLowerCase())) {
      this.setState({ emailValid: true, showError: false })
    } else if (email && email.length > 0) {
      this.setState({ emailValid: false, showError: true, errorMessage: 'Ugyldig mailadresse!' })
      this.shakeInput()
    } else {
      this.setState({ emailValid: false, showError: false })
    }
  }

  shakeInput() {
    this.setState({shakeInput: true})
    setTimeout(function() {
      this.setState({shakeInput: false})
    }.bind(this), 1000)
  }

  isKontolinkEmail() {
    if (this.state.nextBtnDisabled){return}

    if (this.state.emailValid) {
      this.setState({nextBtnDisabled: true})
      const url = `${API_ENDPOINT}/signup/kontolinkprofile`
      axios.get(url, { params: {mail: this.state.email} }).then(response => {
        if (response.data.name) {
          this.setState({showPassword: true, name: response.data.name, email: response.data.email, errorMessage: ''})
        } else {
          this.setState({showError: true, errorMessage: 'Denne email findes ikke i kontolink'})
          this.shakeInput()
        }
        this.setState({nextBtnDisabled: false})
      })
      .catch(err => {
        alert('Netværksfejl. Prøv igen eller kontakt vores support.')
        this.setState({nextBtnDisabled: false})
      })
    } else {
      this.shakeInput()
    }
  }

  changeMail() {
    this.setState({showPassword: false, name: 'kontolink'})
  }

  pinValidated = ({ accessKey, identityId }) => {
    this.props.kontolinkProfile(this.state.email, 'somethingNew', accessKey, identityId)
    this.setState({redirect: true})
    axios.post(`${API_ENDPOINT}/signup/kontolink`, { mail: this.state.email, accessKey, identityId })
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur()
    }
  }


  render() {
    if (this.state.redirect) {
      return <Redirect push to="/" />;
    }
    return (
      <div id="KontolinkSignupContainer">
        <SignupHeader prev={this.state.showPassword ? () => this.changeMail() : this.props.Prev} />
        <div className="mainContainer">
          <div className="kontolinklogo">
            <img src={kontolinklogo} height="60" alt="kontolink" />
            <p>{this.state.name}</p>
          </div>
          {this.state.showPassword
            ? <div>
              <PinCode pinSuccess={this.pinValidated} info={{email: this.state.email}} />
            </div>
            : <div>
              <label>
                <input className={this.state.shakeInput ? 'shakeInput' : ''}  type="email" placeholder=" "
                onChange={e => (this.setState({ email: e.target.value }))}
                onBlur={(e) => { this.validateEmail(e.target.value) }}
                onKeyPress={this.handleKeyPress}
                />
                {this.state.showError ? <span className="errorMsg">{this.state.errorMessage}</span> : <span>Indtast email</span>}
              </label>
              <div className={this.state.nextBtnDisabled ? 'nxtButton disabled' : 'nxtButton'} onClick={() => this.isKontolinkEmail()}>Næste</div>
            </div>
          }
        </div>
        <div className="footNote">
          kontosnap deler persondatapolitik med kontolink
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    kontolinkProfile
  }, dispatch)
}

export default withRouter(connect(null, mapDispatchToProps)(KontolinkSignup))