import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router'
import { SignupHeader } from '../components/SignupHeader'
import Checkbox from 'react-simple-checkbox';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setMail } from '../actions'
import { API_ENDPOINT } from '../config'
import axios from 'axios'
import './EmailSignup.scss'

class EmailSignup extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      email: '',
      emailValid: false,
      showError: false,
      termsAccepted: false,
      redirect: false
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(email).toLowerCase())) {
      this.setState({ emailValid: true, showError: false })
    } else if (email && email.length > 0) {
      this.setState({ emailValid: false, showError: true })
    } else {
      this.setState({ emailValid: false, showError: false })
    }
  }

  done() {
    if (this.state.emailValid && this.state.termsAccepted) {
      this.props.setMail(this.state.email)
      this.setState({ redirect: true })
      axios.post(`${API_ENDPOINT}/signup/email`, {mail: this.state.email})
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur()
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/" />;
    }
    return (
      <div id="EmailSignupContainer">
        <SignupHeader prev={this.props.Prev} />
        <div className="mainContainer">
          <label>
            <input placeholder=" " type="email" onChange={e => (this.setState({ email: e.target.value }))} onBlur={(e) => { this.validateEmail(e.target.value) }} onKeyPress={this.handleKeyPress} />
            {this.state.showError ? <span className="errorMsg">Ugyldig mailadresse!</span> : <span>Indtast email</span>}
          </label>
          <label>
            <Checkbox
              size={4}
              borderThickness={1}
              color={{ backgroundColor: '#000', borderColor: '#000', uncheckedBorderColor: '#000', tickColor: '#fff' }}
              onChange={(isChecked) => { this.setState({ termsAccepted: isChecked }) }}
              checked={this.state.termsAccepted}
            />
            <div className="radioDescription">Acceptér venligst vores <a href='https://kontolink.com/privacy/'>Persondatapolitik</a></div>
          </label>
        </div>
        <div className="bottomContainer">
          <div onClick={() => this.done()}
            className={this.state.emailValid && this.state.termsAccepted ? 'button blue' : 'button disabled'}>Færdig</div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setMail
  }, dispatch)
}

export default withRouter(connect(null, mapDispatchToProps)(EmailSignup))
