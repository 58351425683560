import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearError } from '../actions'
import ErrorPageIcon from '../svg/error-page.svg'
import { Redirect } from 'react-router-dom'

import './ErrorPage.scss'

export class ErrorPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      error: false,
      redirectTo: null
    }
  }

  componentDidMount () {
    this.setState({ error: this.props.error })
    this.props.clearError()
  }

  redirectRetry () {
    let path = '/gallery'
    // const { location } = this.props
    // if (location && location.state && location.state.referrer != null) {
    // TODO: If retry should take you to a different location than /gallery, if you are at a specific place
    // This is where we need to handle it
    // }

    this.setState({ redirectTo: path })
  }

  redirectLogin () {
    this.setState({ redirectTo: '/signup' })
  }

  render () {
    if (this.state.redirectTo != null) {
      return <Redirect to={this.state.redirectTo} />
    }

    return <div id='ErrorPage'>
      <div className='error-page_top'>
        <img className='error-top_icon' src={ErrorPageIcon} alt='error' />
      </div>

      <div className='error-page_middle'>
        <div className='description'>
          Der skete desværre en fejl
        </div>

        <div className='buttons'>
          <button className='button' onClick={() => this.redirectLogin()}>Genstart kontosnap</button>
          <button className='button primary' onClick={() => this.redirectRetry()}>Prøv igen</button>
        </div>
      </div>

      <div className='error-page_bottom'>
        Kontakt supporten: 71 99 97 70
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearError
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage)
